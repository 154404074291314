import React from 'react';
import '../../../App.css';


function Pyra() {
	return (
		<div className="project__container black__container" >
			<div>
				<img src='images/project images/Pyra/Pyra-01.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-02.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-03.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-04.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-05.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-06.jpg' ></img>
				<img src='images/project images/Pyra/Pyra-07.jpg' ></img>
			</div>
		</div>
	)
}

export default Pyra;