import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Home() {
	return (
		<div>
			<HeroSection />
		</div>
	);
}

export default Home;