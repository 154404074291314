import React from 'react';
import '../../App.css';
import Cards from '../Cards';


function Projects() {
	return (
		<div>
			{/* <video src="/videos/about.mp4" autoPlay loop muted /> */}
			<Cards />
		</div>
	)
}

export default Projects;