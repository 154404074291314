import React from 'react';
import '../../../App.css';


function AlphaWebsite() {
	return (
		<div className="project__container black__container" >
			<div>
				<img src='images/project images/Alpha website-01.png' ></img>
				<img src='images/project images/Alpha website-02.png' ></img>
				<img src='images/project images/Alpha website-03.png' ></img>
				<img src='images/project images/Alpha website-04.png' ></img>
				<img src='images/project images/Alpha website-05.png' ></img>
				<img src='images/project images/Alpha website-06.png' ></img>
				<img src='images/project images/Alpha website-07.png' ></img>
				<img src='images/project images/Alpha website-08.png' ></img>
			</div>
		</div>
	)
}

export default AlphaWebsite;